import React from 'react';
// Structure imports
import Layout from '@components/structure/Layout.jsx';
import Seo from '@components/utility/SEO';
import Hero from '@components/content/hero/Hero';
import './styles.scss';

const References = () => {
	return (
		<Layout>
			<Seo title="References | EMFLAZA® (deflazacort)" metaRobots="noindex"/>
			<div className='page--references'>
				<Hero
					heroImage='references-poly.png'
					title='References'
					useBreadcrumbs={false}
				/>
				<div className='px-8 mb-56 text-sm  sm:max-w-4xl lg:px-20 text-emflaza-blue-500 pt-12 md:pt-0'>
					<h4 className='font-bold '>REFERENCES</h4>
					<span>
						<strong> 1.</strong> Bello L, Gordish-Dressman H, Morgenroth LP,
						et al. Prednisone/prednisolone and deflazacort regimens in the
						CINRG Duchenne Natural History Study. <em >Neurology</em>.
						2015;85(12):1048-1055. doi:10.1212/WNL.0000000000001950.
					</span>
					<span>
						<strong> 2.</strong> Marden JR, Freimark J, Yao Z, Signorovitch J,
						Tian C, Wong BL. Real-world outcomes of long-term prednisone and
						deflazacort use in patients with Duchenne muscular dystrophy:
						experience at a single, large care center. <em >J Comp Eff Res.</em> 2020;9(3):177-189. doi:10.2217/cer-2019-0170.
					</span>
					<span>
						<strong> 3.</strong> Emflaza. Package insert. PTC Therapeutics,
						Inc; 2020.
					</span>
					<span>
						<br className='hidden md:inline' />
						<strong> 4.</strong> Birnkrant DJ, Bushby K, Bann CM, et al.
						Diagnosis and management of Duchenne muscular dystrophy, part 1:
						diagnosis, and neuromuscular, rehabilitation, endocrine, and
						gastrointestinal and nutritional management [published correction
						appears in Lancet Neurol. 2018 Apr 4]. <em>Lancet Neurol</em>.
						2018;17(3):251-267. doi:10.1016/S1474-4422(18)30024-3.
					</span>
					<span>
						<strong> 5.</strong> Bushby K, Finkel R, Birnkrant DJ, et al.
						Diagnosis and management of Duchenne muscular dystrophy, part 1:
						diagnosis, and pharmacological and psychosocial management. <em>
							Lancet
							Neurol
						</em>. 2010;9(1):77-93. doi:10.1016/S1474-4422(09)70271-6.
					</span>
					<span>
						<strong> 6.</strong> Parente L. Deflazacort: therapeutic index,
						relative potency and equivalent doses versus other
						corticosteroids. <em>BMC Pharmacol Toxicol</em>. 2017;18(1):1. Published
						2017 Jan 5. doi:10.1186/s40360-016-0111-8.
					</span>
					<span>
						<strong> 7.</strong> Griggs RC, Miller JP, Greenberg CR, et al.
						Efficacy and safety of deflazacort vs prednisone and placebo for
						Duchenne muscular dystrophy. <em>Neurology</em>. 2016;87(20):2123-2131.
						doi:10.1212/WNL.0000000000003217.
					</span>
					<span>
						<br className='hidden md:inline' />
						<strong> 8.</strong> Weber DR, Hadjiyannakis S, McMillan HJ,
						Noritz G, Ward LM. Obesity and Endocrine Management of the Patient
						With Duchenne Muscular Dystrophy. <em>Pediatrics</em>. 2018;142(Suppl
						2):S43-S52. doi:10.1542/peds.2018-0333F.
					</span>
					<span>
						<strong> 9.</strong> Bianchi ML, Biggar D, Bushby K, Rogol AD,
						Rutter MM, Tseng B. Endocrine aspects of Duchenne muscular
						dystrophy. <em>Neuromuscul Disord</em>. 2011;21(4):298-303.
						doi:10.1016/j.nmd.2011.02.006.
					</span>
					<span>
						<strong> 10.</strong> McDonald CM, Henricson EK, Abresch RT, et
						al. Long-term effects of glucocorticoids on function, quality of
						life, and survival in patients with Duchenne muscular dystrophy: a
						prospective cohort study. <em>Lancet</em>. 2018;391(10119):451-461.
						doi:10.1016/S0140-6736(17)32160-8.
					</span>
					<span>
						<strong> 11.</strong> Supplement to: McDonald CM, Henricson EK,
						Abresch RT, et al. Long-term effects of glucocorticoids on
						function, quality of life, and survival in patients with Duchenne
						muscular dystrophy: a prospective cohort study. <em>Lancet</em>. 2017;
						published online Nov 22.
						http://dx.doi.org/10.1016/S0140-6736(17)32160-8.
					</span>
					<span>
						<strong> 12.</strong> FDA approves deflazacort for Duchenne
						muscular dystrophy but manufacturer delays launch over price
						concerns. <em>The Pharmaceutical Journal</em>. 2017.
						doi:10.1211/pj.2017.20202344.
					</span>
					<span className='break-words'>
						<strong> 13.</strong> Imperatives For Duchenne MD. Parent Project
						Muscular Dystrophy. https://www.parentprojectmd.org/wp
						content/uploads/2018/04/ImperativesForDuchenneMD.pdf. Accessed
						March 24, 2021.
					</span>
					<span>
						<strong> 14.</strong> McDonald CM, Henricson EK, Abresch RT, et
						al. The cooperative international neuromuscular research group
						Duchenne natural history study--a longitudinal investigation in
						the era of glucocorticoid therapy: design of protocol and the
						methods used. <em>Muscle Nerve</em>. 2013;48(1):32-54.
						doi:10.1002/mus.23807.
					</span>
					<span>
						<strong> 15.</strong> Moxley RT 3rd, Ashwal S, Pandya S, et al.
						Practice parameter: corticosteroid treatment of Duchenne
						dystrophy: report of the Quality Standards Subcommittee of the
						American Academy of Neurology and the Practice Committee of the
						Child Neurology Society. <em>Neurology</em>. 2005;64(1):13-20.
						doi:10.1212/01.WNL.0000148485.00049.B7.
					</span>
				</div>
			</div>
		</Layout>
	);
};

export default References;
